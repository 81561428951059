@import '~@styles/breakpoints.scss';

.BackDrop {
  padding: 24px 16px 16px;
  position: absolute;
  right: 0;
  top: 55px;
  min-width: 25rem;
  min-height: 15rem;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  box-shadow: 0px 24px 40px rgba(30, 142, 109, 0.2);
  border-radius: 0px 0px 6px 6px;
  z-index: 9999;
  cursor: default;
  max-height: 80vh;
  overflow-y: auto;

  @media only screen and (max-width: $md) {
    right: 10px;
  }
}

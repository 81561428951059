@import '~@styles/colors.scss';

.Root {
  
}

.Button {
  min-width: 200px !important;
  margin: 6px auto !important;
}

.Caption {
  font-size: 16px;
  line-height: 20px;
  margin: 6px auto !important;
  color: $kasp_color_gray_60;
}

@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Root {
  align-items: center;
  border: 1px solid;
  border-radius: 3px;
  display: flex;
  font-family: inherit;
  font-size: 15px !important;
  font-weight: 500;
  height: 47px;
  justify-content: center;
  line-height: 20px;
  min-width: 64px;
  padding: 0 12px;
  letter-spacing: 0.4px;
  cursor: pointer;

  &Variant {
    &Standart {
    }

    &Filled {
    }

    &Outlined {
    }
  }

  input {

    font-family: 'KasperskySans-300', sans-serif !important;
    letter-spacing: 0px !important;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:active  {
      outline: none !important;
      border: none !important;
      border-bottom: inherit !important;
      box-shadow: transparent !important;
      background: transparent !important;
      -webkit-text-fill-color: inherit !important;
      -webkit-box-shadow: 0 0 0 1000px rgba(255,255,255,0.001) inset !important;
      -webkit-transition-delay: 99999s;
      transition-delay: 99999s;
    }

    // &:hover {
    //   border: 2px solid #BDE0F5;
    // }

  }
  input[type=password] {
    font: initial!important;
    font-size: 52px!important;
    @media only screen and (min-width: $md){
      height: 12px!important;
    }
    &::placeholder {
      font-size: 16px;
      font-family: 'KasperskySans-300', sans-serif !important;
      position: relative;
    }
  }
  &:before {
    content: none!important;
    border-bottom: none!important;
  }
  &:after {
    content: none!important;
    border-bottom: none!important;
  }
}

@import '~@styles/colors.scss';

.Root {
  position: relative;
  transition: all .2s;
  border-right: 1.5px solid $kasp_color_gray_10;
  user-select: none;
}

.Static {
  padding: 9px 9px 9px 12px;
  display: flex;
  justify-content: space-between;
  width: 105px;
  cursor: pointer;
  //background: $kasp_color_white;

  div {
    display: flex;
    align-items: center;
  }

  svg {
    margin: 0 8px 0 0;
  }
}

.DropDown {
  position: absolute;
  background: $kasp_color_white;
  width: 105px;
  box-shadow: 2px 6px 10px rgba(92, 133, 150, 0.35);
  border-radius: 3px;
  z-index: 2;
  padding: 0 0 4px 0;

  &Item {
    padding: 6px 9px 6px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      margin: 0 8px 0 0;
    }

    &:hover {
      background: $kasp_color_gray_10;
      color: $kasp_color_green;
      font-weight: 500;
    }

    &Active {
      font-weight: 700;
      color: $kasp_color_green;

      &:hover {
        font-weight: 700;
      }
    }


  }
}

@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Root {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: rgb(#1D1D1B, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  height: auto;

  &.Hidden {
    display: none;
  }
}

.Wrapper {
  height: 110px !important;
  background: transparent;

  @media only screen and (max-width: $sm) {
    height: 170px !important;
  }

  @media only screen and (min-width: $md) {
    height: 72px !important;
  }

  @media only screen and (min-width: $xl) {
    padding: 0 !important;
  }
}

.Container {
  margin: 20px auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: transparent;
  font-size: 14px;

  @media only screen and (min-width: $md) {
    margin: 0;
    align-items: center;
  }

  @media only screen and (max-width: $sm) {
    margin: 0;
    flex-direction: column;
    justify-content: space-evenly;

    & > div:last-child {
      width: 100%;
    }
  }

}

.Content {
  width: 70%;
  color: #fff;
  font-size: 14px;
  font-family: 'KasperskySans-300', sans-serif;

  @media only screen and (max-width: $sm) {
    width: 100%;
  }

  a {
    text-decoration: none;
    font-size: 14px;
    color: #00A88E;
    &:hover {
      color: #23D1AE;
    }
  }
}

.Button {
  font-family: 'KasperskySans-400', sans-serif;

  @media only screen and (max-width: $sm) {
    width: 100% !important;
    margin: 0 auto !important;
  }

  @media only screen and (min-width: $md) {
    margin-left: 10em;
  }
}

.Logo {
  color: #fff;
}

.Info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media only screen and (min-width: $md) {
    align-items: center;
  }

  div:first-child {
    margin-right: 1em;
  }

  span {
    width: 100%;
  }
}

@import '~@styles/breakpoints.scss';

.Root {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;

  @media only screen and (min-width: $md) {
    flex-wrap: nowrap;

    &[data-type = "vertical_cards"] {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

}

@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

$zIndex: 999;

.Root {
  position: sticky !important;
  top: 0;
  background: $kasp_color_white !important;
  box-shadow: none !important;
  width: 100%;
  display: flex;
  height: auto;
  z-index: $zIndex !important;

  &With {
    &Shadow {
      box-shadow: 0 1px 4px rgba(92, 133, 150, 0.25) !important;
    }
  }

  &Centered {
    &Logo {
      a {
        margin: 0 auto;
      }

      @media only screen and (min-width: $lg) {
        a {
          margin: initial;
        }
      }
    }
  }

  &[data-is-login="true"] {
    // height: 64px;
    @media only screen and (min-width: $md) {
      height: 128px;
    }
    @media only screen and (min-width: $lg) {
      // height: 64px!important;
      justify-content: center;
    }
  }

  &[data-is-open-sandwich='true'] {
    position: fixed !important;
    background: #FFFFFF !important;
    @media only screen and (min-width: $md) {
      position: static !important;
    }
  }
}

.DesktopMenu {
  display: none !important;

  &.isLogin {
    @media only screen and (min-width: $md) {
      display: flex !important;
      width: 100%;
      order: 3;
    }
  }

  @media only screen and (min-width: $md) {
    display: flex !important;
    width: 100%;
    margin-left: 16px;
  }
  @media only screen and (min-width: $lg) {
    width: auto;
    order: 2;
    max-width: initial;
    flex: 1;
    margin-left: 16px;
  }
}

.BackgroundMobileMenu {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 64px;
  left: 0;
  background: rgba(29, 29, 27, 0.6);
  z-index: 3;
  @media only screen and (min-width: $md) {
    display: none !important;
  }
}

.ContainerMobileMenu {
  position: fixed;
  width: 100%;
  top: 64px;
  left: 0;
  z-index: 3;
  overflow: auto;
  height: 100vh;
  padding-bottom: 64px;
  @media only screen and (min-width: $md) {
    display: none !important;
  }
}

.MobileMenu {
  height: initial !important;
  width: 100% !important;
  background: #F8F8F8;
  z-index: 3;
  top: initial !important;
  padding-bottom: 12px;
  padding-top: 12px;

  div > span {
    display: none !important;
  }

  button {
    padding-bottom: 12px !important;
    padding-top: 12px !important;
    min-height: initial !important;
    font-size: 18px !important;
    line-height: 22px !important;

    &[aria-selected="true"] {
      span {
        color: #00A88E !important;
      }
    }

    span {
      flex-direction: initial !important;
      justify-content: initial !important;
    }
  }

  @media only screen and (min-width: $md) {
    display: none !important;
  }
}

.RightSide {
  display: flex;
  align-items: center;
}

.ButtonText {
  display: none;
  letter-spacing: 0;
  @media only screen and (min-width: $lg) {
    display: initial;
    font-size: 14px !important;
    color: #1D1D1B !important;
    white-space: nowrap;
  }
}

.UserProfile {
  display: flex !important;
  // span {
  //   span {
  //     margin-right: 0!important;
  //   }
  // }
  @media only screen and (max-width: $md) {
    margin-left: -20px !important;
  }
}

.UserSignIn {
  padding: 4px 0 4px 5px !important;
}

.UserSignInIcon {
  font-size: 14px !important;
}

.Toolbar {
  justify-content: space-between;
  min-height: 64px !important;
}

.SearchLog {
  display: initial;
  right: 120px;

  &[data-show='true'] {
    // width: auto;
    // max-width: 262px;
    max-width: initial;
    /* right: 55px; */
    left: 0;
    width: auto;
  }
  @media only screen and (min-width: $md) {
    right: 510px;
    &[data-show='true'] {
      max-width: 262px;
      width: 100%;
      left: auto;
    }
  }
  @media only screen and (min-width: $lg) {
    &[data-show='true'] {
      max-width: 150px;
    }
  }
}

.Search {
  display: initial;
  right: 50px;

  &[data-show='true'] {
    // width: auto;
    // max-width: 262px;
    max-width: initial;
    /* right: 55px; */
    left: 0;
    width: auto;
  }
  @media only screen and (min-width: $md) {
    right: 100px;
    &[data-show='true'] {
      max-width: 262px;
      width: 100%;
      left: auto;
    }
  }
  @media only screen and (min-width: $lg) {
    &[data-show='true'] {
      max-width: 578px;
    }
  }
}

.ToolbarLogin {
  min-height: 64px !important;
  justify-content: space-between;
  @media only screen and (min-width: $md) {
    flex-wrap: wrap;
  }

  @media only screen and (min-width: $lg) {
    flex-wrap: nowrap;
  }
}

.BrandPortalLogo {
  display: inline-flex !important;
  color: #1D1D1B;
  font-size: 28px;
  line-height: 32px;
  width: 100%;
  text-decoration: none;
  font-family: 'KasperskySans-500', sans-serif !important;
  white-space: nowrap;
  flex: 1;

  @media only screen and (min-width: $md) {
    color: #444444;
    max-width: 160px;
  }
}

.Limiter {
  @media only screen and (min-width: $xl) {
    padding: 0 !important;
  }
}

.Container {
  display: flex;
  align-items: center;
  width: auto;
  justify-content: flex-end;
  @media only screen and (min-width: $md) {
    order: 2;
  }
  @media only screen and (min-width: $lg) {
    justify-content: space-between;
    order: 3;
  }
}

.FavoritesButton,
.LightboxButton {
  @media only screen and (min-width: $md) {
    padding-right: 56px !important;
    padding-left: 34px !important;
    &::after {
      content: '';
      position: absolute;
      background: url("~@assets/icons/small_arrow.svg") 0 0 no-repeat;
      width: 9.6px;
      height: 6px;
      right: 38px;
      transform: scale(1.2);
      top: 22px;
    }
  }

  @media only screen and (max-width: $md) {
    display: none !important;
  }

  &.Active {
    &::after {
      top: 20px;
      transition: 3ms;
      transform: scale(1.2) rotate(180deg);
      background: url("~@assets/icons/small_arrow_active.svg") 0 0 no-repeat;
    }
  }
}

.EditMode {
  display: none !important;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  font-family: 'KasperskySans-500', sans-serif !important;
  @media only screen and (min-width: $md) {
    display: inline-flex !important;
  }

  i {
    position: relative;
    display: inline-block;
    margin-right: .5rem;
    width: 32px;
    height: 16px;
    background-color: #ccc;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 12px;
      height: 12px;
      background-color: #fff;
      border-radius: 11px;
      transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
      transition: all 0.25s linear;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 12px;
      height: 12px;
      background-color: #fff;
      border-radius: 11px;
      transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
      transition: all 0.25s linear;
    }
  }

  input {
    display: none;

    &:checked + i::after {
      transform: translate3d(18px, 2px, 0);
    }

    &:checked + i::before {
      transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
    }

    &:checked + i {
      background-color: #00A88E;
    }
  }
}

.Content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
}

.LeftSide {
  display: flex;
  align-items: center;
}

.BoxShadowNode {
  box-shadow: none !important;
}

.BackUp {
  padding: 4px 0 4px 10px !important;
  margin-right: 30px !important;

  span {
    span:last-child {
      margin-right: 0!important;
    }

    span {
      &::after {
        content: '';
        position: absolute;
        background: url("~@assets/icons/small_arrow.svg") 0 0 no-repeat;
        width: 9.6px;
        height: 6px;
        right: 3px;
        transform: scale(1.2);
        top: 22px;
      }
    }
  }

  &.Active {
    span {
      span {
        &::after {
          top: 20px;
          transition: 3ms;
          transform: scale(1.2) rotate(180deg);
          background: url("~@assets/icons/small_arrow_active.svg") 0 0 no-repeat;
        }
      }
    }
  }
  @media only screen and (min-width: $md) {
    padding: 4px 20px 4px 0 !important;
  }

  @media only screen and (max-width: $md) {
    display: none !important;
  }
}

.FavoritesButton,
.LightboxButton,
.BackUp {
  transition: 3ms;

  &.Active {
    svg {
      path {
        fill: $kasp_color_btn_default !important;
      }
    }

    span:last-of-type {
      color: $kasp_color_btn_default !important;
    }

    .Remove {
      margin: 10px 10px 0 -10px;
      width: 20px;
      height: 20px;
      z-index: 100;
      cursor: pointer;

      svg {
        path {
          fill: $kasp_color_gray_60 !important;
        }
      }
    }
  }
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  cursor: default;
  z-index: 999;
}

.FavoritesBackDrop,
.LightBoxBackDrop {
  right: 35px;

  @media only screen and (max-width: $md) {
    right: 10px;
  }
}

.Count {
  padding: 0.6rem 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 10px;
  min-width: 1rem;
  height: 1rem;
  background: $kasp_color_btn_default;
  color: #FFFFFF;
  border-radius: 2rem;
  font-size: 12px;

  &.Round {
    padding: 0.6rem;
    width: 1rem;
  }

  &.Empty {
    display: none;
  }

  @media only screen and (max-width: $lg) {
    // left: -15px;
  }
}

.Recent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media screen and (max-width: $md) {
    grid-template: none;
    // grid-template-columns: 1fr;
  }
}

.RecentItems {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.RecentTitle {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 24px;
  color: #1D1D1B;
  text-align: left;
}

.FavoritesBackDrop {
  min-width: initial;
  min-height: initial;
  width: calc(2 * 16px + 325px + 24px + 24px + 16px); // paddings + asset card + 2 buttons
  max-height: min(calc(24px + 16px + 48px + 8 * (64px + 16px)), calc(100vh - 64px)); // (paddings + button + 8 assets) or (vh - header)
}

.Favorites {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.OpenAllFavorites {
  padding: 0 !important;
  height: auto !important;
  line-height: 24px !important;
  margin-block: 12px !important;
  color: $kasp_color_btn_default !important;
}

.FavoritesList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.FavoritesItem {
  display: flex;
  justify-content: space-between;

  & .AssetCard {
    margin-top: 0;
    margin-bottom: 0;
    width: 325px !important;
  }

  & .Buttons {
    display: flex;
    gap: 16px;

    & .Button {
      cursor: pointer;

      svg {
        path {
          fill: $kasp_color_gray !important;
        }
      }
    }
  }
}

.Lightbox {
  display: flex;
  flex-direction: column;
}

.LightboxActionButtons {
  display: flex;
}

.LightboxItem {
  position: relative;
  display: flex;
  flex-direction: row;
}

.Clear, .Download, .Share {
  font-size: 18px !important;
  letter-spacing: 0 !important;

  svg {
    transform: scale(1.2) !important;
  }
}

.Clear {
  margin-bottom: 10px !important;

  svg {
    transform: scale(1.3) rotate(35deg) !important;
  }
}

.Download {
  margin-right: 16px !important;
}

.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $kasp_color_gray_90 !important;
}

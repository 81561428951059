@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Root {
  border: none;
  width: 100%;
  position: relative;
  margin: 16px 0;

  &Type {

    &image {
      .Dropzone {
        height: 185px;
      }
    }

    &two_images, &two_do_images {
      .Dropzone {
        height: 185px;
      }
    }

    &three_images, &three_do_images {
      .Dropzone {
        height: 185px;
      }
    }

    @media only screen and (min-width: $sm) {
      &image {
        .Dropzone {
          height: 324px;
        }
      }

      &two_images, &two_do_images {
        .Dropzone {
          height: 153px;
        }
      }

      &three_images, &three_do_images {
        .Dropzone {
          height: 96px;
        }
      }
    }

    @media only screen and (min-width: $md) {

      &image {
        .Dropzone {
          height: 448px;
        }
      }

      &two_images, &two_do_images {
        .Dropzone {
          height: 214px;
        }
      }

      &two_images, &two_do_images,
      &three_images, &three_do_images {
        margin: 0 16px;

        &:first-child {
          margin: 0 16px 0 0;
        }
        &:last-child {
          margin: 0 0 0 16px;
        }
      }
      &three_images, &three_do_images {
        .Dropzone {
          height: 137px;
        }
      }
    }

    @media only screen and (min-width: $lg) {
      &image {
        .Dropzone {
          height: 597px;
        }
      }

      &two_images, &two_do_images {
        .Dropzone {
          height: 290px;
        }
      }

      &three_images, &three_do_images {
        .Dropzone {
          height: 186px;
        }
      }
    }

  }

  &Is{
    &Selected {
      .Dropzone {
        border: 4px solid $kasp_color_green_100 !important;
      }
    }
    &Edit {
      .Dropzone {
        border: 4px solid transparent;
      }
    }
  }
}

.Dropzone {
  width: auto;
  flex: 1 auto;
  position: relative;
}

.Select {
  outline: 1.5px solid $kasp_color_gray_20;
  max-width: 130px;
  margin: 0 0 8px 0;
  border: none !important;
  color: #444444 !important;
}

.SelectActive {
  outline: 1.5px solid $kasp_color_gray_20;
  max-width: 130px;
  margin: 0 0 8px 0;
  background: rgba(35, 209, 174, 0.60) !important;
  color: #444444 !important;
}

.DropDownItem {
  color: #444444;
  &:hover {
    color: #444444;
  }
}

.StaticItem {
  color: #444444;
  font-family: 'KasperskySans-500', sans-serif;
}

.TextBlock {
  width: 100%;
}

.DropDown {
  width: 100%;
  outline: 1.5px solid $kasp_color_gray_20;
  max-width: 130px;
  border-radius: 0;
}

.CoverBg {
  pointer-events: none;
  background: $kasp_color_green;
  border: 4px solid $kasp_color_green;
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  &[data-show="true"] {
    opacity: .24;
    z-index: 100;
  }
}

.Caption {
  width: 100%;
  border: transparent;
  outline: transparent;
  font-family: 'KasperskySans-300', sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: $kasp_color_gray_90;
  margin: 8px 0 -8px 0;

  &::placeholder {
    color: $kasp_color_gray;
    user-select: none;
  }
}

.doIcon {
  svg {
    width: 32px;
    height: 32px;
  }
}

@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 16px 0;
  width: 100%;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
  
  @media only screen and (min-width: $md) {
    margin: 16px;
    
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &Is {
    &Selected {
      .CardRoot {
        outline: 4px solid $kasp_color_green;
        background: linear-gradient(0deg, rgba(35, 209, 174, 0.24), rgba(35, 209, 174, 0.24)), #EBF5F5;
      }
    }
  }

  &Variant {
    &Vertical {
      @media only screen and (min-width: $sm) {
        width: 48% !important;
        .CardRoot {
          width: auto !important;
        }
      }
      @media only screen and (min-width: $md) {
        flex: 1 1 auto;
        width: 30% !important;
        .CardRoot {
          width: 332px !important;
        }
      }
      @media only screen and (min-width: $lg) {
        flex: 1 1 auto;
        width: 30% !important;
      }
    }
  }

}

.CardRoot {
  width: 100%;
}

.CardTitle {
  font-size: 21px !important;
  line-height: 24px !important;
  font-weight: normal !important;
}

.AddButton {
  margin: 0 0 0 32px !important;
  flex: 1;
  
  .PlusButton {
    background-color: #F2F2F2 !important;
    svg {
      fill: #444444 !important;
    }
  }
}


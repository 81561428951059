.Root {
  display: flex;
  flex-direction: column;
}

.Additional {
  font-family: 'KasperskySans-300', sans-serif !important;
  font-size: 10px;
  line-height: 12px;
}

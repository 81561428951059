@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Card {
  position: relative;
  display: flex;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%) !important;
  box-shadow: 0px 3.21486px 16.0743px rgba(92, 133, 150, 0.24) !important;
  border-radius: 6px !important;
  cursor: pointer;

  &.Horisontal {
    flex-direction: row;
  }

  &.Vertical {
    flex-direction: column;
    height: 360px;
    .Media {
      position: relative;
      height: 197px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // visibility: hidden;
          // opacity: 0;
        background: linear-gradient(0.88deg, rgba(14, 40, 60, 0.06) -9.5%, rgba(14, 40, 60, 0) 36.97%);
        transition: visibility 0.3s linear, opacity 0.3s linear;
      }
    }

    &:hover {
      .Media {
        position: relative;
        & > div {
          visibility: visible;
          opacity: 1;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          visibility: visible;
          opacity: 1;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.5) 100%);
          // z-index: 1;
        }
      }
    }

    &.Small {
      height: 250px;
      .Media {
        position: relative;
        height: 133px;
      }
    }
  }

  &.Short {
    width: 360px;
    margin: 8px 0;
    background: #FFFFFF;
    box-shadow: none !important;
  }

  &.IsCollection {
    outline: 1.5px solid #23D1AE;
    background:
        linear-gradient(135deg, transparent 75%, rgba($kasp_color_btn_default, 0.1)),
        linear-gradient(190deg, #FFFFFF 90%, rgba($kasp_color_btn_default, 0.05)) !important;
  }

  .CardActions {
    padding: 10px 0 0;
  }
}

.Media {
  position: relative;
  height: 197px;
  img {
    transition: all ease 0.25s;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    max-height: 197px;
  }

  .ExpireDate {
    position: absolute;
    top: 24px;
    left: 24px;
    opacity: 0.9;
    padding: 10px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    border-radius: 8px;
    margin: 0;
    background-color: #FFFFFF;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &.Short {
    width: 96px;
    height: 64px;
    img {
      width: 100%;
      height: 100%;
      min-width: 96px;
      min-height: 64px;
      border-radius: 6px;
    }
  }

  & > div {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
  }
}

.Name {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical;
  font-size: 16px !important;
  line-height: 18px !important;
  margin-top: 0 !important;
  color: #1D1D1B;
  font-weight: 600 !important;
  font-family: 'KasperskySans-300', sans-serif !important;
  word-wrap: break-word;
  letter-spacing: 0.02rem; // font spacing

  &.Short {
    max-width: 197px;
    padding: 0 !important;
    margin: 0 !important;
    text-align: left;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 18px !important;
    letter-spacing: 0 !important;
    color: #1D1D1B;
    font-family: 'KasperskySans-500', sans-serif !important;
    cursor: pointer;
    word-wrap: break-word;
  }
}

.Title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1D1D1B;
}

.TopPanel {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 4px 0 20px;
}

.Collection {
  display: flex;
  align-items: center;
  color: $kasp_color_btn_default;
  font-weight: 700;
  margin-right: 5px;

  &:first-child {
    font-size: 12px;
  }

  span:not(:first-of-type) {
    margin: 0 2px;
    font-size: 14px;
  }
}

.LastUpdate {
  font-size: 12px;
  color: #999999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-bottom: 1px;

  &.Short {
    margin: 5px 0 0;
    font-size: 14px;
    color: #1D1D1B;
    text-align: left;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0 !important;
  }
}

.Count {
  font-size: 14px !important;
  box-shadow: none !important;
  margin-left: 5px !important;
  // font-weight: 400;
}

.Dot {
  margin-left: 4px !important;
}

.Icon {
  display: flex;
  align-items: center;
  margin: 0 -4px 0 -2px !important;
  svg {
    transform: rotate(45deg) scale(0.85);
    path {
      fill: $kasp_color_btn_default;
    }
  }
}

.Content {
  line-height: 16px !important;

  &.Short {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &.WithActions {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
    }
  }
}


.ActionPanel {
  right: 8px !important;
  top: 90px !important;
  gap: 8px !important;
}

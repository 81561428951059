@import '~@styles/breakpoints.scss';

.Root {
  position: relative;
  margin: 20px 0;
  width: 100%;

  @media only screen and (min-width: $md) {
    width: 80%;
  }

  @media only screen and (min-width: $lg) {
    width: 83%;
  }

  &Is {
    &Limited {
      margin: 0 0 20px 0;

      @media only screen and (min-width: $lg) {
        width: 100%;
      }
    }
  }
}

ul {
  width: 100%;
}

li {
  list-style: none;
  width: 100%;
}

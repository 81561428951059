@import '~@styles/colors.scss';

.Root {
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  // border: 4px solid transparent;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &Is {

    &Disabled {
      border: none !important;

      .ActionsPanel {
        display: none !important;
      }

    }

  }
}

.Video {
  width: 100% !important;
  height: 100% !important;
}

.ActionsPanel {
  background: $kasp_color_black;
  border-radius: 3px !important;
  height: 40px;
  opacity: 0.64;
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  button {
    background-color: transparent;
    border: transparent !important;
    outline: transparent;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
    height: 17px;
    width: 17px;
    margin: 3px;
  }
}

.ButtonEdit {
  background-image: url("../../../assets/icons/pensil.svg");
}

.ButtonEditLink {
  background-image: url("../../../assets/icons/edit_link.svg");
}

.ButtonDelete {
  background-image: url("../../../assets/icons/trash.svg");
}

.ButtonBack {
  background-image: url("../../../assets/icons/arrow_back_active.svg");
  background-size: auto 40% !important;

  &:disabled {
    background-image: url("../../../assets/icons/arrow_back.svg");
  }

}

.ButtonForward {
  background-image: url("../../../assets/icons/arrow_forward_active.svg");
  background-size: auto 40% !important;

  &:disabled {
    background-image: url("../../../assets/icons/arrow_forward.svg");
  }

}

@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

$gap: 12px;

.TableMenu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: calc(30px - $gap) 0 40px;
  font-size: 16px;

  @media only screen and (min-width: $md) {
    width: 100%;
  }

  @media only screen and (min-width: $lg) {
    width: 50%;
    margin-top: calc(-75px - $gap);

    &[data-is-edit="true"] {
      margin-top: calc(-50px - $gap);
    }
  }
}

.Tabs {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.Tab {
  padding: 8px 16px;
  margin: $gap $gap 0 0;
  display: flex;
  text-align: center;
  background-color: $kasp_color_gray_0;
  color: $kasp_color_gray_60;
  font-family: 'KasperskySans-500', sans-serif;
  border-radius: 17px;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: all ease 0.3s;

  &.isEdit {
    cursor: default;
    color: $kasp_color_white;
    background-color: $kasp_color_gray_20;
    pointer-events: none;
  }

  &:not(.isEdit) {
    &:hover, &:active {
      // transition: all ease 0.3s;
      // color: $kasp_color_white;
      // background-color: $kasp_color_purple;
    }
  }

  &.Active {
    transition: all ease 0.3s;
    color: $kasp_color_white;
    background-color: $kasp_color_btn_default;
  }
}

.Empty {
  display: none !important;
  padding: 8px 16px;
  margin: $gap $gap 0 0;
  display: flex;
  text-align: center;
  background: transparent;
  color: $kasp_color_gray;
  font-family: 'KasperskySans-500', sans-serif;
  border-radius: 17px;
  border: 1px solid $kasp_color_gray_20;
}

.EditMode {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
}

.EditTab {
  display: flex;
  margin-bottom: 32px;
  height: 220px;
  max-height: 220px;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  box-shadow: 0 2.93803px 14.6902px rgba(92, 133, 150, 0.24);

  &:hover, &:active {
    box-shadow: 0px 4px 20px rgba(92, 133, 150, 0.35);
  }

  &.Disabled {
    opacity: 0.9;
    pointer-events: none;
  }
}

.EditContent {
  padding: 15px 0 0;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  line-height: 24px;
  .Link {
    font-family: 'KasperskySans-500', sans-serif;
    color: #00A88E;
    &:hover {
      text-decoration: none !important;
      color: #23D1AE;
    }
  }
}

.DrugAndDrop {
  display: flex;
  width: 52px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover, &:active {
    svg {
      circle {
        fill: #00A88E;
      }
    }
  }
  @media only screen and (min-width: $md) {
    width: 70px;
  }
}

.Title {
  display: flex;
  & > span {
    margin-right: 15px;
    font-weight: 700 !important;
    font-size: 28px !important;
    line-height: 32px !important;
  }
}

.AddButton {
  justify-content: center;
  height: 72px;
  background: #EBF5F5;
  cursor: pointer;
  width: 100%;
  margin: 24px 0 32px;

  &.Disabled {
    background: #F2F2F2!important;
    border: 2px solid #F2F2F2!important;
    color: #CCCCCC!important;
    cursor: default;
    button {
      color: #CCCCCC!important;
      span > svg {
        path {
          fill: #CCCCCC!important;
        }
      }
    }
  }

  button {
    color: #00A88E!important;
    font-size: 21px!important;
    line-height: 24px!important;
  }
}

.Actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 12px;
  svg {
    width: 32px;
    height: 32px;
    path {
      fill: #ADC9D5!important;
    }
    &:hover {
      path {
        fill: #00A88E!important;
      }
    }
  }
}

.EditModeContent {
  overflow: hidden;
  padding: initial!important;
  padding: 16px 20px 0 25px !important;
  width: 100%;

  @media only screen and (min-width: $md) {
  }
}

.TitleBase {
  margin: 0 0 18px 0 !important;
}

.LinkBase {
  margin: 0 0 10px 0 !important;
}

.TitleInput {
  input {
    &::placeholder {
      font-family: 'KasperskySans-300' , sans-serif !important;
      color: #999999;
      opacity: 1;
    }
  }
  border: 2px solid #ccc !important;
  input, label {
    font-family: 'KasperskySans-700', Helvetica, Arial, sans-serif !important;
    font-size: 28px;
    line-height: 32px;
    font-weight: bold;
  }
  label {
    top: 8px !important;
    left: 14px !important;
    font-weight: normal !important;
    letter-spacing: 0 !important;
  }
}

.LinkInput {
  input {
    font-weight: bold;
    color: #00A88E;
    &::placeholder {
      font-family: 'KasperskySans-300' , sans-serif !important;
      color: #999999;
      opacity: 1;
    }
  }
  border: 2px solid #ccc !important;
}

.SortableList {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.EditModeButton {
  display: none!important;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  font-family: 'KasperskySans-500', sans-serif !important;
  @media only screen and (min-width: $md) {
    display: inline-flex!important;
  }
  i {
    position: relative;
    display: inline-block;
    margin-right: .5rem;
    width: 32px;
    height: 16px;
    background-color: #ccc;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 12px;
      height: 12px;
      background-color: #fff;
      border-radius: 11px;
      transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
      transition: all 0.25s linear;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 12px;
      height: 12px;
      background-color: #fff;
      border-radius: 11px;
      transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
      transition: all 0.25s linear;
    }
  }
  input {
    display: none;
    &:checked + i::after {
      transform: translate3d(18px, 2px, 0);
    }
    &:checked + i::before {
      transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
    }
    &:checked + i {
      background-color: #00A88E;
    }
  }
}

@import '~@styles/colors.scss';

.Button {
  background-color: $kasp_color_purple !important;
  box-shadow: none !important;

  &:hover {
    background: $kasp_color_purple !important;
    opacity: 0.85;
  }
}

.Icon {
  color: #fff;
}

.ButtonEdit {
  color: #A3E0D6 !important;
  box-shadow: none !important;
}
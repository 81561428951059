@import '~@styles/colors.scss';

.Root {
  position: relative;
  margin-right: 8px;
}

.ItemButton {
  align-items: center;
  display: flex;
  span {
    transition: all ease 0.25s;
  }

  &:hover, &:active {
    span {
      color: $kasp_color_green !important;
    }
  }

  img {
    margin: 0 8px 0 0;
  }
}

.SubMenu {
  background: $kasp_color_white;
  border-radius: 3px;
  box-shadow: 0 4px 20px rgba(92, 133, 150, 0.35);
  height: fit-content;
  left: 95%;
  min-width: 150px;
  padding: 8px 0 8px 0;
  position: absolute;
  transform: translateY(-20%);
  top: 50%;
  z-index: 2;
  transition: all ease 0.25s;
}

.SubMenuItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: 'KasperskySans-500', sans-serif;
  font-size: 12px;
  line-height: 16px;
  padding: 8px;

  &:hover, &:active {
    background: $kasp_color_gray_0;
    color: $kasp_color_green;
  }

  span {
    min-width: 100px;
  }

  img {
    margin: 0 8px 0 0;
    opacity: .8;

    &:hover, &:active {
      opacity: 1;
    }
  }
}

@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Root {
  align-items: center;
  border: 1px solid;
  border-radius: 8px !important;
  display: flex;
  font-family: inherit;
  font-size: 16px !important;
  font-weight: 500;
  height: 47px;
  justify-content: center;
  line-height: 20px;
  min-width: 20px !important;
  padding: 0 12px;
  letter-spacing: 0.4px;
  text-decoration: none !important;
  cursor: pointer !important;

  &[disabled] {
    pointer-events: none;
    cursor: default;
  }

  &Is {
    &FullWidth {
      width: 100%;
    }

    &Loaded {
      pointer-events: none;
    }
  }

  &Variant {
    &Contained {
      background: #00A88E!important;
      box-shadow: 0 8px 32px rgba(52, 208, 184, 0.48) !important;
      &:hover {
        background: #00A88E!important;
      }
      &:active {
        background: #00A88E!important;
        // box-shadow: none!important;
      }
      &:disabled {
        background: #eceff3!important;
        border: 2px solid #eceff3!important;
        color: #9ca4af!important;

        & > span:nth-child(1) {
          color: #9ca4af!important;
        }
      }
    }

    &Text {
      color: #444444 !important;
      // background: transparent;
      // border-color: transparent;
      justify-content: flex-start !important;
    }

    &Outlined {
      border-width: 2px !important;
      background: transparent !important;
      color: #00A88E !important;
      &:disabled {
        border: 2px solid #F2F2F2!important;
        color: #CCCCCC!important;
      }
    }
  }
}

.Icon {
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  width: 16px;

  &Variant {
    &End {
      margin: 0 0 0 8px;
    }

    &Start {
      margin: 0 8px 0 0;
    }
  }
}

.Loader {
  animation: spin .8s linear infinite;
  font-size: 20px;
}

@keyframes spin {
  from { transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

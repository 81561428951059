@import '~@styles/colors.scss';

.Root {
  display: none;
  flex-direction: row;
  margin: 0;
  position: absolute;
  z-index: 2;
  transform: translate3d(-55px, -15px, 0);

  &Is {
    &Visible{
      display: flex;
    }
  }
}

.AddButton {
  align-items: center;
  justify-content: center;
  background: $kasp_color_green_100 !important;
  border-radius: 50% !important;
  border: none;
  box-shadow: 0 8px 32px rgba(52, 208, 184, 0.48) !important;
  display: flex;
  font-size: 30px !important;
  font-weight: normal;
  height: 49px;
  justify-content: center;
  outline: none;
  text-align: center;
  width: 49px;
  cursor: pointer;
  transition: all .25s;

  span {
    color: $kasp_color_white;
    line-height: 0;
    // transform: translate(0, -1px) !important;
    transition: all 0.25s;
  }

  &:hover, &:active, &Active {
    background: $kasp_color_gray_10 !important;
    box-shadow: 0 8px 32px rgba(52, 208, 184, 0) !important;
    color: $kasp_color_gray_100 !important;
    span {
      svg path {
        fill: $kasp_color_gray_100;
      }
    }
  }

  &Active {
    span {
      transform: rotate(45deg) translate(0, -1px) !important;
    }
  }
}

.Items {
  align-items: center;
  display: flex;
  margin: 16px 0 0 6px;
  transition: all .2s;
  background: $kasp_color_white;
  height: 20px;
}

@import '~@styles/colors.scss';

.Root {
  
}

.Preview {
  border: none;
  &:hover {
    .ActionsPanel {
      display: inline-flex !important;
    }
  }
}

.ActionsPanel {
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  height: 100%;
  width: 100%;
  opacity: 1;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(29, 29, 27, 0.32), rgba(29, 29, 27, 0.32));
  display: none !important;
}

.ActionsPanelButtonsEdit {
  width: 32px !important;
  height: 32px !important;
}

.ActionsPanelButtonsDelete {
  display: none !important;
}

.Default, .Hover {
  // background-color: transparent;
  // background-repeat: no-repeat;
  // background-size: 32px 100%;
  // background-position: center center;
  // background-image: url("../../../assets/icons/pensil.svg");
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  svg {
    width: 32px;
    height: 32px;
    path {
      fill: #ADC9D5;
    }
  }
  &:hover {
    svg {
      path {
        fill: #00A88E;
      }
    }
  }
}

.Hover {
  background-image: linear-gradient(0deg, rgba(29, 29, 27, 0.32), rgba(29, 29, 27, 0.32));
  svg {
    path {
      fill: #FFFFFF;
    }
  }
}

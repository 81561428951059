@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Input {
  max-width: 904px;
  width: 100%;
  display: flex!important;
  flex-direction: row!important;
  justify-content: initial!important;
  // border: 2px solid #CCCCCC!important;
  border-radius: initial!important;
  div {
    width: 100%;
    input {
      width: 100%;
    }
    &:after {
      content: initial!important;
    }
    &:before {
      content: initial!important;
    }
  }
}

.Container {
  div {
    legend {
      span {
        display: none!important;
      }
    }

    textarea {
      height: 56px!important;
      &::placeholder {
        color: #AEAEAE;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

.TitleBase {
  margin: 0 0 18px 0 !important;
}

.TitleInput {
  input, label {
    font-family: "KasperskySans-700", Helvetica, Arial, sans-serif !important;
    font-size: 28px;
    line-height: 32px;
    font-weight: bold;
  }
  label {
    top: 8px !important;
    left: 14px !important;
    font-weight: normal !important;
    letter-spacing: 0 !important;
  }
}

.DescriptionBase {
  margin: 0 0 16px 0 !important;
}

.Actions {
  margin: initial!important;
  margin-left: -0.5rem !important;
  button {
    font-size: 18px;
    line-height: 24px;
    color: #00A88E;
    text-align: start;

    span {
      display: initial!important;
    }
    &:hover {
      color: #23D1AE;
    }
  }
}

.SubmitContainer {
  display: flex;
  flex-direction: row;
  padding-top: initial!important;
  justify-content: space-between;
  align-items: flex-start;

  @media only screen and (max-width: $md) {
    width: 100%;
    flex-direction: column-reverse;
    align-items: stretch;
  }
}

.Box {
  width: 100%;
}

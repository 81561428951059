@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.DrugAndDrop {
  display: flex;
  width: 52px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover, &:active {
    svg {
      circle {
        fill: #00A88E;
      }
    }
  }
  @media only screen and (min-width: $md) {
    width: 70px;
  }
}

.EditCard {
  display: flex;
  margin-bottom: 32px;
  height: 270px;
  max-height: 270px;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  box-shadow: 0 2.93803px 14.6902px rgba(92, 133, 150, 0.24);

  &:hover, &:active {
    box-shadow: 0px 4px 20px rgba(92, 133, 150, 0.35);
  }

  @media only screen and (max-width: $md) {
    height: 310px;
    max-height: 310px;
  }
}

.CardMediaContainer {
  display: none;
  @media only screen and (min-width: $md) {
    display: block!important;
    width: 220px;
  }
}

.EditModeCardMedia {
  display: none!important;
  position: relative;
  @media only screen and (min-width: $md) {
    display: block!important;
    width: 220px;
    height: 100%;
  }
}

.EmptyImg {
  display: none;
  @media only screen and (min-width: $md) {
    display: initial;
    background: #EBF5F5;
    width: 100%;
    height: 220px;
    max-width: 220px;
  }
}

.EditModeContent {
  overflow: hidden;
  padding: initial!important;
  padding: 16px 20px 0 25px !important;
  width: 100%;

  @media only screen and (min-width: $md) {
  }
}

.Actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 12px;
  svg {
    width: 32px;
    height: 32px;
    path {
      fill: #ADC9D5!important;
    }
    &:hover {
      path {
        fill: #00A88E!important;
      }
    }
  }
}

.Dropzone {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
}

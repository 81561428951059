$xs: 360px;
$sm: 640px;
$md: 1024px;
$lg: 1280px;
$xl: 1440px;
$xxl: 1920px;

:export {
  XS: $xs;
  SM: $sm;
  MD: $md;
  LG: $lg;
  XL: $xl;
  xxl: $xxl;
}

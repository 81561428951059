@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

@font-face {
  font-family: 'KasperskySans-300';
  src: url('./kaspersky-sans/kasperskysans-300.eot');
  src: url('./kaspersky-sans/kasperskysans-300.eot?#iefix') format('embedded-opentype'), url('./kaspersky-sans/kasperskysans-300.woff2') format('woff2'), url('./kaspersky-sans/kasperskysans-300.woff') format('woff'), url('./kaspersky-sans/kasperskysans-300.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'KasperskySans-500';
  src: url('./kaspersky-sans/kasperskysans-500.eot');
  src: url('./kaspersky-sans/kasperskysans-500.eot?#iefix') format('embedded-opentype'), url('./kaspersky-sans/kasperskysans-500.woff2') format('woff2'), url('./kaspersky-sans/kasperskysans-500.woff') format('woff'), url('./kaspersky-sans/kasperskysans-500.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'KasperskySans-700';
  src: url('./kaspersky-sans/kasperskysans-700.eot');
  src: url('./kaspersky-sans/kasperskysans-700.eot?#iefix') format('embedded-opentype'), url('./kaspersky-sans/kasperskysans-700.woff2') format('woff2'), url('./kaspersky-sans/kasperskysans-700.woff') format('woff'), url('./kaspersky-sans/kasperskysans-700.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

:global html {
  box-sizing: border-box;
}

:global body {
  font-family: 'KasperskySans-300', Helvetica, Arial, sans-serif;
  color: $kasp_color_black;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global * {
  box-shadow: none;
  box-sizing: inherit;

  &::after,
  &::before {
    box-sizing: inherit;
  }

  &:focus {
    outline: none;
  }
}

:global h1,
:global h2,
:global h3,
:global h4,
:global h5,
:global h6 {
  margin: 0;
}


:global h1 {
  font-size: 64px;
  line-height: 68px;
  font-family: 'KasperskySans-500', Helvetica, Arial, sans-serif;
}

:global button {
  border: transparent;
  outline: transparent;
  cursor: pointer;
  font-family: 'KasperskySans-300', Helvetica, Arial, sans-serif;
}

:global p {
  font-family: 'KasperskySans-300', Helvetica, Arial, sans-serif;
}

::selection {
  background: transparent;
}

:global div.DraftEditor-root {
  font-family: 'KasperskySans-300', sans-serif;
  font-size: 18px;
  line-height: 24px;
  min-height: 90vh;
}

:global div.public-DraftEditor-content h4 {
    letter-spacing: 0.03em;
}

:global div.public-DraftEditor-content span[style="font-weight: bold;"] {
    letter-spacing: 0.03em;
}

:global .public-DraftEditorPlaceholder-root {
  font-size: 16px;
  line-height: 20px;
  color: $kasp_color_gray;

  a {
    &:visited {
      color: #00A88E !important;
    }
  }
}

:global figure {
  margin: auto 0;
}

:global br {
  &[data-sm],
  &[data-md],
  &[data-lg] {
    display: none;
  }
  @media only screen and (min-width: $sm) {
    &[data-sm] {
      display: inline;
    }
  }
  @media only screen and (min-width: $md) {
    &[data-sm] {
      display: none;
    }
    &[data-md] {
      display: inline;
    }
  }
  @media only screen and (min-width: $lg) {
    &[data-sm],
    &[data-md] {
      display: none;
    }
    &[data-lg] {
      display: inline;
    }
  }
}

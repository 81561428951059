@import '~@styles/breakpoints.scss';
@import '~@styles/colors.scss';

.Content {
  max-width: 536px;
  margin: 0 auto;
}

.Button {
  border-width: 2px!important;
  border-radius: 3px!important;
  height: 48px;
  font-size: 16px!important;
  box-shadow: none!important;
  max-width: 200px!important;
  color: #fff !important;
  background: $kasp_color_purple !important;

  &:disabled {
    color: $kasp_color_gray_20 !important;
    background-color: $kasp_color_gray_10 !important;
    box-shadow: none !important;
  }
  &:hover {
    background: $kasp_color_purple !important;
    opacity: 0.85;
    box-shadow: none !important;
  }
  &:active {
    background: $kasp_color_purple !important;
    box-shadow: none !important;
  }

  @media only screen and (max-width: $md) {
    max-width: initial!important;
    width: 100%!important;
  }
}

.Actions {
  justify-content: flex-start!important;
}

.Code {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
  @media only screen and (max-width: $md) {
    display: block;
  }
  div {
    width: 100%;
  }
}

.SendTitle {
  position: relative;
  padding-left: 26px;
  font-size: 32px;
  line-height: 36px;
  color: #1D1D1B;
  text-align: center;
  max-width: 117px;
  margin: 0 auto;
  font-weight: bold;
  letter-spacing: 2px;
  font-family: 'KasperskySans-500', sans-serif;
  @media only screen and (max-width: $md - 1) {
    font-size: 21px;
    line-height: 24px;
  }
  &:before {
    @media only screen and (max-width: $md - 1) {
      width: 20px;
      height: 15px;
      left: 18px;
      top: 5px;
      transform: scale(1.2);
    }
    content: '';
    position: absolute;
    background: url("~@assets/icons/check.svg") no-repeat;
    width: 26px;
    height: 20px;
    left: 0;
    top: 15px;
    transform: scale(1.8);
  }
}

.Message {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #1D1D1B;
  margin-top: 16px;
  margin-bottom: 24px;
}

.CloseButton {
  display: block!important;
  font-size: 16px!important;
  line-height: 20px!important;
  width: 200px!important;
  height: 48px!important;
  margin: 0 auto!important;
  background: #8233FF !important;
  border-radius: 3px !important;
  @media only screen and (max-width: $md - 1) {
    width: 100%!important;
  }
}

.MessageModal {
  padding-left: 80px;
  padding-right: 80px;
  @media only screen and (max-width: $md - 1) {
    padding-left: 16px;
    padding-right: 16px;
    div:nth-child(2) {
      height: initial!important;
      width: initial!important;
      max-width: initial!important;
    }
  }
  @media only screen and (max-height: $md) and (orientation: landscape) {
    align-items: center!important;
    i {
      top: 17px!important;
      right: 17px!important;
      background: url("~@assets/icons/close.svg") no-repeat!important;
    }
  }
  @media only screen and (max-width: $md - 1) {
    i {
      background: url('~@assets/icons/white_close.svg') no-repeat;
      top: -30px;
      right: 0;
    }
  }
}

.Container {
  width: 100%;
  overflow: hidden;
  @media only screen and (max-width: $md - 1) {
    height: 100vh;
    margin: initial!important;
    max-height: initial!important;
    max-width: initial!important;
  }
}

.Title {
  h2 {
    width: 100%;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px !important;
    line-height: 36px !important;
    font-weight: bold;
    letter-spacing: 2px;
    padding-top: 18px!important;
  }
}

.CloseIcon {
  @media only screen and (max-width: $md - 1) {
    top: -28px!important;
    right: 0!important;
    color: #fff!important;
    &:before {
      color: #FFF!important;
    }
  }
}

.AltContainer {
  width: 100%!important;
  max-width: 480px!important;
  overflow: initial;
  overflow-y: initial!important;
}

@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Root {
  position: absolute;
  // right: 50px;
  width: 16px;
  > form {
    width: 100%;
  }
  &[data-show='true'] {
    max-width: 550px;
    width: 100%;
    display: flex;
    transition: all ease-in 0.15s;
    svg {
      fill: #00A88E;
    }
    label {
      display: initial!important;
    }
  }
  @media only screen and (min-width: $md) {
    // right: 100px;
  }
}

.ShowSearch {
  max-width: 550px;
  width: 100%;
  svg {
    fill: #00A88E!important;
  }
}

.Container {
  display: flex;
  align-items: center;
  svg {
    width: 22px;
    height: 22px;
    fill: #AEAEAE;
    cursor: text;
  }
  div > span {
    display: none;
  }
}

.Input {
  display: flex!important;

  label {
    z-index: 2;
    padding-left: 28px;
    color: #999999!important;
    transition: none!important;
    transform: none!important;
    top: 13px!important;
    font-family: 'KasperskySans-300', sans-serif !important;
    pointer-events: none!important;
    font-size: 16px;
    line-height: 20px;
    &:before {
      content: '';
      position: absolute;
      background: url("~@assets/icons/gray_search.svg") no-repeat;
      width: 16px;
      height: 16px;
      left: 0;
      top: 3px;
      display: block!important;
    }
    &::placeholder {
      visibility: hidden;
    }
    &[data-shrink=true] {
      visibility: hidden!important;
      color: #999999!important;
      &:before {
        content: '';
        position: absolute;
        background: url("~@assets/icons/green_search.svg") no-repeat;
        width: 16px;
        height: 16px;
        left: 0;
        top: 3px;
        display: block!important;
        visibility: visible!important;

      }
    }
    @media only screen and (max-width: $md) {
      &:before {
        background: url("~@assets/icons/green_search.svg") no-repeat;
      }
    }
  }
  div {
    width: 100%;
    height: 48px!important;
    margin-top: initial!important;
    cursor: pointer !important;
    &:before {
      border-bottom: 2px solid #CCCCCC!important;
      transition: none!important;
      display: none;
    }
    &:after {
      border-bottom: 2px solid #23D1AE!important;
      transition: none!important;
      display: none;
    }
    input {
      height: 48px!important;
      margin-left: 28px!important;
      padding: initial!important;
      font-family: 'KasperskySans-300', sans-serif;
      font-size: 16px;
      line-height: 20px;
      color: #444444!important;
      border-bottom: 2px solid #CCCCCC!important;
      transition: background-color 9999s !important;

      &:focus {
        border-bottom: 2px solid #23D1AE!important;
      }

      &:-webkit-autofill {
        outline: none !important;
        box-shadow: none !important;
        -webkit-text-fill-color: inherit !important;
        -webkit-box-shadow: 0 0 0 1000px rgba(255,255,255,0.001) inset !important;
        background-color: transparent !important;
        border-bottom: 2px solid #CCCCCC !important;
        animation-name: none !important;
        transition: background-color 9999s !important;
      }
      &:-webkit-autofill::selection,
      &:-webkit-autofill::selection:hover,
      &:-webkit-autofill::selection:focus,
      &:-webkit-autofill::selection:active,{
        background-color: transparent !important;
        animation-name: none !important;
        transition: background-color 9999s !important;
      }
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active  {
        outline: none !important;
        box-shadow: none !important;
        -webkit-text-fill-color: inherit !important;
        -webkit-box-shadow: 0 0 0 1000px rgba(255,255,255,0.001) inset !important;
        animation-name: none !important;
        border-bottom: 2px solid #23D1AE !important;
        transition: background-color 9999s !important;
      }
    }
  }
}

.SearchContainer {
  width: 100%;
}

.SearchIcon {
  position: absolute;
  top: 13px;
  pointer-events: none;
}

.SearchIconAlt {
  position: absolute;
  top: 94px;
  pointer-events: none;
  @media only screen and (min-width: $md){
    top: 158px;
  }
}

.SearchNoLogin {
  position: absolute;
  top: 94px;
  pointer-events: none;
}

.SearchLogin {
  position: absolute;
  top: 165px;
  pointer-events: none;
}

.ResultsCount {
  display: inline-block;
  font-size: 18px;
  line-height: 24px;
  color: #444444;
  font-weight: normal;
}

.CardRoot {
  margin: 0 0 36px !important;
  height: auto !important;
}

.Footer {
  position: inherit!important;
  top: calc(100vh - 56px);
}

.Actions {
  margin-top: 2px!important;
}

.SearchButton {
  width: 100px!important;
  height: 48px!important;
  border: 2px solid #00A88E!important;
  border-radius: 3px!important;
  margin-left: 12px!important;
  color: #00A88E!important;
  font-size: 16px!important;
  line-height: 20px!important;
  &:disabled {
    border: 2px solid #F2F2F2!important;
    color: #CCC!important;
  }
  &:hover {
    background-color: transparent !important;
  }
  @media only screen and (min-width: $md) {
    width: 140px!important;
  }
}

.Search {
  position: inherit !important;
  margin-bottom: 24px;
  &[data-show='true'] {
    max-width: initial;
  }
  @media only screen and (min-width: $lg) {
    display: none;
  }
}

.DataWrapper {
}

.Card_Title {
  font-size: 36px !important;
  line-height: 40px !important;
  margin: 0 0 12px 0 !important;
}

.CardName {
  display: block !important;
  padding: 0 !important;
  margin: 0 auto 5px !important;
  font-family: 'KasperskySans-400', sans-serif !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #666666;
}

.CardDescription {
  font-family: 'KasperskySans-300', sans-serif !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  max-width: 732px;
  padding: 0 0 99px 0;
}

.SearchButtonShort {
  padding: 0;
  margin-left: 12px;

  width: 48px;
  height: 48px;

  background: #00A88E;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;

  &:disabled {
    opacity: 0.9;
    cursor: default;
    svg {
      cursor: default;
    }
  }

  svg {
    cursor: pointer;
    transform: scale(0.7);
    path {
      fill: #fff;
    }
  }
}

@import '~@styles/reset.scss';
@import '~@styles/colors.scss';

::selection {
  background: $kasp_color_green;
  color: $kasp_color_white;
}

.Root {
  display: flex;
  width: 100%;
}

.Loader {
  position: fixed;
}

body {
  overflow-y: scroll;
  
  [class*="--is-focused"] {
    outline: none!important;
    border: 2px solid #CCCCCC;

    &:hover {
      outline: none!important;
    }
  }

  [class$="-menu"] {
    // position: absolute;
    // margin-top: 0;
    // margin-top: -0.6rem;
    border-radius: 0;
    border: 2px solid #CCCCCC;
    border-top: none !important;
    box-shadow: none !important;
  }

  [class$="-MenuList"] {
    padding: 0;
    margin: 0.5rem 0;
    border: 0 !important;
  }

  [class$="-option"] {
    padding: 15px 20px;
    font-size: 16px;
    border: 0;
    color: #444444;
  }

  [class*="--is-selected"], [class*="--is-focused"] {
    background-color: inherit !important;
    font-weight: bold !important;
  }
}

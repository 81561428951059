// KASPERSKY COLOR PALLET

// GENERAL COLORS
$kasp_color_black: #1D1D1B;
$kasp_color_white: #FFF;
$kasp_color_gray: #AEAEAE;
$kasp_color_green: #00A88E;
$kasp_color_purple: #8233FF;

// GRAY SHADES
$kasp_color_gray_100: #444444;
$kasp_color_gray_90: #666666;
$kasp_color_gray_60: #999999;
$kasp_color_gray_20: #CCCCCC;
$kasp_color_gray_10: #F2F2F2;
$kasp_color_gray_0: #F8F8F8;

// RED SHADES
$kasp_color_red_error: #FF3748;

// GREEN SHADES
$kasp_color_green_100: #23D1AE;
$kasp_color_green_0: #EBF5F5;

// PRIMARY BUTTON COLOR
$kasp_color_btn_default: #00A88E;
$kasp_color_btn_hover: #23D1AE;
$kasp_color_btn_active: #265951;
$kasp_color_btn_disable: #F2F2F2;


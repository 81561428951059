@import '~@styles/colors.scss';

.ModalContainer {
  min-width: 80% !important;
  width: 100% !important;
  max-width: 1200px !important;
  margin: 0 !important;
  height: 100% !important;
  max-height: none !important;
}

.ModalContent {
  display: flex;
  flex-direction: column;
  padding: 16px 64px !important;
}

.ModalActions {
  display: flex;
  justify-content: space-between !important;
  padding: 12px 64px !important;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(92, 133, 150, 0.24);
}

.ModalButton {
  margin: 12px 0 !important;
}

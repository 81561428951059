@import '~@styles/colors.scss';

.ModalContainer {
  min-width: 480px !important;
}

.ModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ModalParagraph {
}

.ModalActions {
  display: flex;
  justify-content: space-between !important;
  padding: 8px 24px !important;
}

.ModalButton {
  margin: 12px 0 !important;
}

@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Tabs {
  position: initial;
  left: 0;
  margin: initial;
  // position: absolute;
  // top: 65px;
  // height: 64px;
  top: initial;
  left: initial;
  & > div > span {
    background: #23D1AE !important;
    height: 4px;
  }
  @media only screen and (min-width: $lg){
    margin-left: 16px;
    max-width: 458px;
    width: 100%;
  }
}

.Tab, .TabHome {
  min-width: initial !important;
  color: #1D1D1B !important;
  font-size: 14px !important;
  line-height: 18px !important;
  padding: 8px 16px !important;
  min-height: 64px !important;
  &[aria-selected="false"] {
    opacity: 1 !important;
  }
  span {
    &:nth-child(2) {
      display: none !important;
    }
  }
}

.TabHome {
  color: #999999 !important;
  span {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
  }
  svg {
    margin: 0 8px 0 0 !important;
    path {
      fill: #999999;
    }
  }
}

.Tab {
  &.Selected {
    color: $kasp_color_green !important;
  }
}

.SubMenu {
  // position: absolute;
  // top: 254px;
  // height: 122px;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  background: #FFFFFF;
  align-items: flex-start;
  a {
    padding-left: 16px;
    padding-top: 18px;
    font-size: 14px !important;
    color: #1D1D1B !important;
  }
  button {
    font-size: 14px !important;
    line-height: 18px;
    height: 61px;
    text-align: left;
    padding-left: 16px;
    font-family: 'KasperskySans-500', sans-serif !important;
    background: #FFFFFF;
    &:first-of-type {
      color: #1D1D1B;
    }
    &:last-of-type {
      color: #AEAEAE;
      span > span:last-of-type > span {
        font-family: 'KasperskySans-300', sans-serif !important;
        color: #1D1D1B;
        padding-left: 12px;
        letter-spacing: -0.3px;
      }
    }
  }
}

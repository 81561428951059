@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Root {
  background: $kasp_color_white;
  box-shadow: 0px 1px 4px rgba(92, 133, 150, 0.25) !important;
  display: flex;
  align-items: center;
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 9;
  height: auto !important;
}

.Container {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: flex-start;
  align-content: space-between;
  height: 93px !important;
  padding-top: 7px;
  padding-bottom: 0px;
  
  @media only screen and (min-width: $md) {
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;
    height: 64px!important;
  }
  @media only screen and (min-width: $xl) {
    padding: 0 !important;
  }

  &[data-is-login="true"] {
    flex-direction: column;
    justify-content: initial!important;
    align-items: initial!important;
    button:first-of-type {
      padding-left: 0!important;
    }
    button {
      padding: 8px 12px 0!important;
      min-width: initial!important;
      @media only screen and (min-width: $md) {
        padding: 6px 16px 8px!important;
      }
    }
    @media only screen and (min-width: $md) {
      justify-content: space-between !important;
      align-items: center;
      flex-direction: row;
      min-width: initial!important;
      button:first-of-type {
        padding-left: 12px!important;
      }
      button:last-of-type {
        padding-right: 0!important;
      }
    }
  }
}

.Items {
  margin: 6px 0 0 0;
  button, a {
    min-width: auto !important;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  @media only screen and (min-width: $md) {
    margin: 0 0 0 0;
  }
}

.Item {
  flex: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  display: flex;
  align-items: center;
  text-align: center;
  color: #00A88E !important;
  span {
    font-size: 14px!important;
  }
  
}


@import '~@styles/colors.scss';

.Link {
  color: #00A88E;
  text-decoration: none;
  font-family: 'KasperskySans-500', sans-serif;
}

.Section {
  display: flex;
  flex-direction: row;

  &:hover {
    .Actions {
      display: flex !important;
    }
  }

  .Title {
    color: #1D1D1B;
    text-decoration: none;
    font-size: 36px;
    line-height: 40px;
    flex: 1;
  }

  .Actions {
    display: none !important;
    align-items: center;
    display: inline-flex;
    margin-left: 21px;
  }

  .Button {
    margin-left: 16px;
  }

}

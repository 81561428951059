@import '~@styles/colors.scss';

.Root {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  &Selected {
    border: 4px solid $kasp_color_green;
    background: linear-gradient(0deg, rgba(35, 209, 174, 0.24), rgba(35, 209, 174, 0.24)), #EBF5F5;
  }

}

.ActionsPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  user-select: none !important;

  button {
    background-color: transparent;
    border: transparent !important;
    outline: transparent;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
    height: 17px;
    width: 17px;
    margin: 3px;
  }
}

.ButtonDelete {
  // background-image: url("../../../../assets/icons/SeparateLink/delete.svg");
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;

  svg path {
    transition: all ease 0.25s;
  }
  
  &:hover, &:active {
    svg path {
      fill: #FF3748;
    }
  }
}

.Button, .Autocomplete {
  margin: 0 20px 0 0 !important;
}

.Autocomplete {
  min-width: 400px !important;
  width: auto;
}

.Button {
  width: 199px;
  min-width: 208px !important;
}

.Progress {
  margin: 0 auto !important;
  display: block !important;
}

.ControlLabel {
  user-select: none !important;
}

.Checkbox {
  z-index: 10;
}

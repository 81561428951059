@import '~@styles/colors.scss';

.Root {
  overflow: hidden;
  width: 100%;

  &Selected {
    border: 4px solid $kasp_color_green;
    background: linear-gradient(0deg, rgba(35, 209, 174, 0.24), rgba(35, 209, 174, 0.24)), #EBF5F5;
  }

  :global .slick {
    &-list {
      width: auto;
    }

    &-track {
      display: flex;
    }

    &-slide {
      width: 100%;
    }

    &-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 16px 0 0 0;

      div {
        & > li {
          &.slick-active {
            & div {
              width: 11px;
              height: 11px;
              background: $kasp_color_green;
            }
          }
        }
      }
    }
  }

  &Is {

    &Read {
      :global .slick {
        &-track {
          position: relative !important;
          width: 100% !important;
        }
    
        &-slide {
          top: 0 !important;
          left: 0 !important;
          position: absolute !important;
        }
      }
    }

  }
}

.Container {
  position: relative;
  width: 100%;

  .Slide {
    position: absolute !important;
  }
}

.Slider {
  max-width: 400px !important;
  display: block !important;
  margin: 0 auto 8px auto !important;
}

.Dots {
  display: flex;
  align-items: center;
  list-style: none;
}

.Dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $kasp_color_gray_20;
  margin: 0 3px 0 3px;
  cursor: pointer;
}

.PrevButton {
  background: url("../../../../assets/icons/Carousel/slider_arrow_prev_active.svg");
  border: transparent;
  outline: transparent;
  width: 17px;
  height: 28px;
  cursor: pointer;
  margin: 0 32px 0 0;

  &Disable {
    background: url("../../../../assets/icons/Carousel/slider_arrow_prev.svg");
  }
}

.NextButton {
  background: url("../../../../assets/icons/Carousel/slider_arrow_next_active.svg");
  border: transparent;
  outline: transparent;
  width: 17px;
  height: 28px;
  cursor: pointer;
  margin: 0 0 0 32px;

  &Disable {
    background: url("../../../../assets/icons/Carousel/slider_arrow_next.svg");
  }
}

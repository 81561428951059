@import '~@styles/breakpoints.scss';
@import '~@styles/colors.scss';

.ErrorMessage {
  display: block;
  font-size: 10px;
  line-height: 12px;
  color: $kasp_color_red_error;
}

.Error {
  border-color: $kasp_color_red_error!important;

  &Icon {
    margin: 0 4px 0 0;
  }
}

.TextArea {
  font-family: 'KasperskySans-300', sans-serif !important;
  height: 120px!important;
  resize: none;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  &::placeholder {
    color: #999999;
  }
  padding: 6px 12px 7px;
  border: 2px solid #CCCCCC;
  transition: all ease 0.25s;

  &:hover {
    border: 2px solid #BDE0F5;
  }
  &:focus, &:active {
    border: 2px solid #CCCCCC;
    border-bottom: 2px solid #23D1AE;
  }
}

@import '~@styles/colors.scss';

.Root {
  position: relative;

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none !important;
      background-image: url('../../../../assets/icons/listdot.svg');
      background-repeat: no-repeat;
      background-position: 0 4px;
      padding: 0 0 0 30px;
      margin: 6px 0 !important;
    }
  }

  h2 {
    margin: 20px 0 32px 0;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0 !important;
    letter-spacing: 0.02rem !important; // font spacing
  }
  h4 {
    margin: 10px 0 24px 0;
    font-size: 21px;
    line-height: 24px;
  }

  div {
    &[data-block="true"] {
      margin: 24px 0 24px 0;
    }
  }
}

.Headline {
  display: flex;
  justify-content: space-between;
}

.Title, .TitleHeading {
  display: inline-flex;
  margin: 0 0 0 0 !important;

  div, label {
    border: none !important;
    padding: 0 !important;
    &:before, &:after {
      content: none !important;
    }
  }

  label, input {
    font-family: 'KasperskySans-700', sans-serif !important;
    font-weight: bold !important;
    font-size: 36px !important;
    line-height: 40px !important;
    letter-spacing: 0 !important;
    text-align: left;
  }

  label {
    color: #AEAEAE !important;
    top: 4px !important;
  }

  input {
    color: #1D1D1B !important;
  }
}

.TitleBase {
  margin: 0 0 22px 0 !important;
}

.TitleHeading {
  margin: 0 0 24px 0 !important;
  letter-spacing: -0.03rem !important; // font spacing
}

.Actions {
  display: inline-flex;
  align-items: center;
  margin-left: 21px;
}

.Button {
  margin-left: 8px !important;
  span {
    transition: all ease 0.25s;
  }

  &:hover, &:active {
    color: $kasp_color_green !important;
  }
}

.EditorWrapper {
  position: relative;
}

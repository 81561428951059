@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Root {
  min-width: 208px !important;
  margin: 0 20px 0 0 !important;
  width: 300px;
}

.TextField {
  color: #00A88E;
}

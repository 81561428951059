@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Root {
  width: auto;
  user-select: none;
  flex: 1 auto;
  display: none;

  @media only screen and (min-width: $md) {
    display: flex;
    flex-direction: column;
    width: 20%;
    // min-width: 165px;

    position: sticky !important;
    top: 82px;
    // height: 100%;
    width: auto;
    user-select: none;
    flex: 1 auto;
    // display: none;
    // overflow-x: hidden;
    // overflow-y: auto scroll;
    scrollbar-width: thin !important;
    margin-right: 15px !important;
    margin-top: -30px !important;
    height: max-content;

    &::-webkit-scrollbar-thumb {
      background-color: #CCCCCC;
      outline: 1px solid #CCCCCC;
      border-radius: 0.5em;
    }

    &::-webkit-scrollbar {
      width: 0.5em;
    }
  }

  @media only screen and (min-width: $lg) {
    width: 17%;
    min-width: 220px;
  }
}

.ListItem, .ListSubItem {
  cursor: pointer;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding-top: 4px !important;
}

.ListItem {
  padding-left: 0 !important;
  white-space: pre-wrap;
  word-break: break-all;
  letter-spacing: 0.03rem !important; // font spacing
}

.ListSubItem {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: -10.5px 0;
  letter-spacing: 0 !important; // font spacing

  .Selected {
    span {
      color: $kasp_color_btn_default;
    }
  }
}

.ListItemText {

  span {
    font-family: 'KasperskySans-300', sans-serif !important;
    font-size: 18px !important;
    line-height: 22px !important;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Selected {
    span {
      color: $kasp_color_btn_default;
    }
  }

  &NotSaved {
    i {
      &:after {
        content: '';
        background-image: url("../../../../assets/icons/alert_not_saved.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 16px;
        height: 16px;
        display: block;
        margin: 0 6px;
      }
    }
  }
}

.ListSubItemText span {
  font-family: 'KasperskySans-300', sans-serif !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.LockIcon {
  margin: 0 4px 0 8px !important;
  width: 20px !important;
  height: 20px !important;
  color: #ADC9D5;
}

.tab {
  display: flex;
  align-items: center;
  column-gap: 8px;

  &_active span {
    color: #00A88E !important;
  }

  & span {
    font-family: 'KasperskySans-300', sans-serif !important;
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    color: #1D1D1B;
    padding: 0 0 28px;
  }
}

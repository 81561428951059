@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Root {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 20px;
  height: 20px;
  align-items: center;
  margin-right: 20px;
  @media only screen and (min-width: $md) {
    display: none!important;
  }
  i {
    background: #AEAEAE;
    width: 20px;
    height: 3px;
    &:nth-child(1) {
      margin-bottom: 4px;
    }
    &:last-child {
      margin-top: 4px;
    }
  }
  &[data-show-sandwich="true"] {
    margin-top: -1px;
    padding-right: 20px;
    i {
      background: #00A88E;
      width: 22px;
      &:nth-child(1) {
        transform: rotate(-45deg) translate(0, 9.7px);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(45deg) translate(0, -9.7px);
      }
    }
  }
}

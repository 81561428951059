@import '~@styles/breakpoints.scss';

.Root {
  font-feature-settings: 'liga' off;

  &Variant {
    &Caption {
      font-size: 12px;
      line-height: 16px;
    }

    &H1 {
      font-size: 36px !important;
      line-height: 40px !important;
      font-weight: bold !important;
      letter-spacing: 1.3px !important;
      margin: 15px 0 !important;

      @media only screen and (min-width: $md) {
        font-size: 48px !important;
        line-height: 52px !important;
        letter-spacing: -0.03em !important;
      }

      @media only screen and (min-width: $lg) {
        font-size: 64px !important;
        line-height: 68px !important;
        letter-spacing: -0.01em !important;
      }
    }

    &H2 {
      font-size: 28px !important;
      font-weight: bold !important;
      line-height: 32px !important;
      letter-spacing: initial !important;
      margin: 12px 0 !important;

      @media only screen and (min-width: $md) {
        font-size: 32px !important;
        line-height: 36px !important;
      }

      @media only screen and (min-width: $lg) {
        font-size: 36px !important;
        line-height: 38px !important;
      }
    }

    &H3 {
      font-size: 26px;
      font-weight: normal;
      line-height: 32px;
      margin: 10px 0 !important;
    }

    &H4 {
      font-size: 20px;
      font-weight: normal;
      line-height: 24px;
      margin: 10px 0 !important;
    }

    &H5 {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 10px 0 !important;
    }

    &H6 {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      margin: 10px 0 !important;
    }

    &P {
      font-size: 14px;
      line-height: 20px;
      margin: 10px 0 !important;
    }

    &Subtitle {
      font-size: 18px !important;
      line-height: 24px !important;
      margin: 10px 0 !important;

      @media only screen and (min-width: $md) {
        font-size: 21px !important;
        line-height: 24px !important;
      }

      @media only screen and (min-width: $lg) {
        font-size: 21px !important;
        line-height: 24px !important;
      }
    }
  }
}

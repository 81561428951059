@import '~@styles/breakpoints.scss';

.Select {
  [class$="-control"] {
    padding: 5px 10px;
    // margin-top: -60px;
    border: 2px solid #CCCCCC;
    min-width: 20em;
    outline: none !important;
    font-weight: 300;
    font-size: 16px;
    color: #999999;
    z-index: 10;
    border-radius: 0;

    &:hover {
      outline: none!important;
    }

    // @media only screen and (max-width: $md) {
    //   margin-bottom: 10px;
    // }
  }

  [class$="-indicatorContainer"] {
    svg {
      color: #1D1D1B !important;
    }
  }

  [class$="-indicatorSeparator"] {
    display: none;
  }

  [class*="--is-focused"] {
    outline: none!important;
    border: 2px solid #CCCCCC;

    &:hover {
      outline: none!important;
      border-color: #CCCCCC!important;
    }
  }

  [class*="--menu-is-open"] {
    border-bottom-color: #23D1AE !important;

    &:hover {
      border-bottom-color: #23D1AE !important;
    }
    
    & [class$="-indicatorContainer"] {
      svg {
        transform: rotate(180deg);
      }
    }

    // &~[class$="-menu"] {
    //   z-index: 9 !important;
    // }
  }

  // [class^="Select_Select__2YDRB__input"] {

  // }

  // [class$="-option"] {
  //   padding: 10px 20px;
  //   font-size: 16px;
  //   border: 0;
  //   color: #444444;
  // }

  // [class~="Select_Select__2YDRB__option--is-selected"], [class~="Select_Select__2YDRB__option--is-focused"] {
  //   background-color: inherit !important;
  //   font-weight: bold !important;
  // }
}
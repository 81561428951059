@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Root {
  overflow: hidden;
  width: 100%;

  :global .slick {
    &-list {
      width: auto;
    }

    &-track {
      display: flex;
    }

    &-slide {
      width: 100%;
    }

    &-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 16px 0 0 0;

      div {
        & > li {
          &.slick-active {
            & div {
              width: 11px;
              height: 11px;
              background: $kasp_color_green;
            }
          }
        }
      }
    }
  }
}

.Dots {
  display: flex;
  align-items: center;
  list-style: none;
}

.Dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $kasp_color_gray_20;
  margin: 0 3px 0 3px;
  cursor: pointer;
}

.PrevButton, .NextButton {
  cursor: pointer;

  svg {
    width: 24px;
    height: 48px;

    path, rect {
      fill: #00A88E;
    }
  }

  @media only screen and (min-width: $md) {
    svg {
      width: 17px;
      height: 28px;
    }
  }

  &Is {
    &Disabled {
      cursor: default;
      svg {
        path, rect {
          fill: #CCCCCC;
        }
      }
    }
  }
}

.PrevButton {
  margin: 0 32px 0 0;
}

.NextButton {
  margin: 0 0 0 32px;
}

.AddSlideButton {
  width: 40px;
  height: 40px;
  background: $kasp_color_green;
  box-shadow: 0 8px 24px rgba(38, 202, 153, 0.24);
  border-radius: 3px;
  margin: 0 0 0 13px;
  color: $kasp_color_white;
  font-family: 'KasperskySans-300', sans-serif;
  font-size: 30px;
  user-select: none;
}

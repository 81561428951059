@import '~@styles/colors.scss';

.ModalContent {
  min-width: 480px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SortableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  user-select: none;
}

.SortableElement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  background: #FFFFFF;
  /* Gray Shadow */

  box-shadow: 0px 4px 20px rgba(92, 133, 150, 0.24) !important;
  border-radius: 3px !important;
  margin:  8px 0;
  padding: 6px 12px;
  z-index: 10000;

  &[data-hidden = "true"] {
    display: none;
  }

  .Title {
    font-size: 21px;
    line-height: 24px;
    color: #1D1D1B;
    text-align: left;
    flex: 1;
    padding: 0 16px;
  }
}

.ModalButton {
  margin: 12px 0 !important;
  box-shadow: none !important;
}

.DnDButton {
  cursor: all-scroll !important;
}

@import '~@styles/breakpoints.scss';

.Root {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.Content {
  // margin: 0 auto;
  // max-width: 1280px;
  // width: 100%;
  flex: 1;
  position: relative;
  @media only screen and (min-width: $xl) {
    padding: 48px 0 0 0 !important;
  }
}

.Root {
  display: flex;
  justify-content: flex-end;
  margin: 28px 0 0;

  & > * {
    flex: 1 1 100%;

    &:not(:first-child) {
      margin: 0 0 0 16px;
    }
  }
}

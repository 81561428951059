@import '~@styles/breakpoints.scss';
@import '~@styles/colors.scss';

.Root {
  &[data-edit = "true"] {
    .Banner {
      .Title {
        &::after {
          content: 'Edit mode';
          display: block;
          color: #00A88E;
          font-weight: bold;
        }
      }
  
      .Button {
        // pointer-events: none;
      }
    }
  }

  @media only screen and (min-width: $lg) {
    & {
      .Banner {
        .Title {
          order: 2 !important;
        }
        .Description {
          margin: 0 0 0 0;
        }
      }
    }

    &[data-edit = "true"] {
      .Banner {
        padding: 16px 0 16px 0;
        .Title {
          font-size: 72px !important;
          order: 5 !important;
        }
        .Description {
          margin: 107px 0 0 0;
        }
      }
      .Items {
        padding: 24px 0 0 0;
      }
    }
  }
    
}

.Banner {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: $md) {}

  @media only screen and (min-width: $lg) {
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16px 0 40px 0;
  }

}

.Description {
  display: flex;
  flex-direction: column;
  order: 2;
  @media only screen and (min-width: $md) {}

  @media only screen and (min-width: $lg) {
    width: 50%;
    max-width: 545px;
    order: 3;
    margin: 120px 0 0 0;
  }
}

.Content {
  display: flex;
  flex-direction: column;
  padding: 16px 0 4px 0;

  @media only screen and (min-width: $md) {
    padding: 32px 0 4px 0;
  }

  @media only screen and (min-width: $lg) {
    padding: 16px 0 14px 0;
  }
}

.Title {
  margin: 0 0 8px 0 !important;
  letter-spacing: 1px!important;
  width: 100%;
  order: 2;
  
  @media only screen and (min-width: $sm) {
    font-size: 48px !important;
    line-height: 51px !important;
    letter-spacing: 0.5px !important;
  }

  @media only screen and (min-width: $lg) {
    order: 5;
    font-size: 96px !important;
    line-height: 100% !important;
    letter-spacing: -1px !important;
    width: 50%;
    height: 100px;
  }
}

.TextArea {
  font-size: 21px;
  line-height: 24px;
  color: #1D1D1B;
  font-weight: bold;
  height: 98px !important;
}

.Save {
  svg {
    width: 32px;
    height: 32px;
    path {
      fill: #ADC9D5!important;
    }
    &:hover {
      path {
        fill: #00A88E!important;
      }
    }
  }
}

.TextAreaBase, .Subtitle {
  margin: 15px 0 16px 0 !important;
  
  @media only screen and (min-width: $md) {
    margin: 25px 0 20px 0 !important;
  }

  @media only screen and (min-width: $lg) {
    margin: 0 0 20px 0 !important;
  }
}

.Subtitle {

  &::before {
    content: '';
    display: block;
    width: 64px;
    height: 0;
    line-height: 0;
    border-top: 2px solid #1D1D1B;
    margin: 0 0 2px 0;
  }

  @media only screen and (min-width: $lg) {
    &::before {
      display: none;
    }
  }
}

.Button, .ButtonSecondary {

  @media only screen and (min-width: $md) {
    max-width: 280px;
  }

  @media only screen and (min-width: $lg) {
    max-width: 231px;
  }
}

.ButtonSecondary {
  border: 2px solid $kasp_color_btn_default !important;

  &:hover:not(.isEdit) {
    color: $kasp_color_btn_hover !important;
    border-color: $kasp_color_btn_hover !important;

    svg {
      path {
        fill:  $kasp_color_btn_hover !important;
      }
    }
  }

  &.isEdit {
    border-color: #CCCCCC !important;
  }
}

.ModalContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.InputBase {
  margin: 0 0 22px 0 !important;
  input {
    min-width: 432px !important;
  }
}

.Items {
  padding: 33px 0 0 0;
  width: 100% !important;
  margin: 0 !important;

  @media only screen and (min-width: $sm) {
    padding: 48px 0 0 0;
  }

  @media only screen and (min-width: $lg) {
    padding: 58px 0 0 0;
  }
}

.Item {
  padding: 0 0 32px 0 !important;

  @media only screen and (min-width: $sm) {
    padding: 0 16px 32px 0 !important;
    &:nth-child(2n) {
      padding: 0 0 32px 16px !important;
    }
  }

  @media only screen and (min-width: $lg) {
    padding: 0 16px 32px 16px !important;
    &:nth-child(2n) {
      padding: 0 16px 32px 16px !important;
    }
    &:first-child, &:nth-child(4n+1) {
      padding: 0 16px 32px 0 !important;
    }
    &:nth-child(4n) {
      padding: 0 0 32px 16px !important;
    }
  }
}

.EditContainer {
  margin: initial;
  padding-left: 0;
  padding-right: 0;
}

.CardRoot {
  height: auto !important;
  padding: 0 0 10px 0 !important;

  @media only screen and (min-width: $md) {
    height: 550px !important;
  }
}

.CardContent {
  padding: 13px 17px !important;

  @media only screen and (min-width: $md) {
  }
}

.CardTitle {

}

.CardDescription {
  margin: 16px 0 0 0 !important;
  font-size: 16px !important;
  line-height: 20px !important;

  @media only screen and (min-width: $md) {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  @media only screen and (min-width: $lg) {
    margin: 23px 0 0 0 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    &::before {
      display: none;
    }
  }
}

.CardMedia {
  width: 100%;
  height: 328px !important;

  @media only screen and (min-width: $md) {
    height: 271px !important;
  }
  
  @media only screen and (min-width: $lg) {
    height: 296px !important;
  }
}

.CardActions {
  width: 100%;
  padding: 16px !important;
}

.Search {
  position: inherit!important;
  &[data-show='true'] {
    max-width: initial;
  }
  @media only screen and (min-width: $lg) {
    display: none;
  }
}

.SearchLogin {
  position: inherit!important;
  display: none;
  margin-bottom: 30px;
  order: 1;
  &[data-show='true'] {
    max-width: initial;
  }
  @media only screen and (min-width: $lg) {
    max-width: 545px!important;
    margin-bottom: 72px;
    display: initial;
    order: 1;
    button {
      display: none;
    }
  }
  @media only screen and (max-width: $md) {
    // display: none !important;
  }
}

.Actions {
  flex-direction: column;
  margin: initial!important;
  display: initial!important;
  @media only screen and (min-width: $sm) {
    margin: initial!important;
    justify-content: space-between!important;
    flex-direction: row;
    display: flex!important;
  }
  button {
    max-width: 100%!important;
    font-size: 16px!important;
    height: 48px!important;
    line-height: 100%;
    
    @media only screen and (min-width: $md) {
      // max-width: 280px !important;
    }
    @media only screen and (min-width: $lg) {
      max-width: 265px !important;
    }
    &:not(:first-child) {
      margin: 16px 0 0 0!important;
      @media only screen and (min-width: $sm) {
        margin: 0 0 0 16px!important;
      }
    }
  }

  .ButtonSave {
    max-width: 80px !important;
  }
}

.AddNewCard {
  justify-content: center;
  height: 72px;
  background: #EBF5F5;
  cursor: pointer;
  width: 100%;
  margin: 24px 0 32px;
  button {
    color: #00A88E!important;
    font-size: 21px!important;
    line-height: 24px!important;
  }
}

@import '~@styles/colors.scss';

.Root {
  align-items: center;
  background: $kasp_color_white;
  border-radius: 3px;
  box-shadow: 0 4px 20px rgba(92, 133, 150, 0.35);
  display: flex;
  height: 40px;
  justify-content: space-between;
  position: absolute;
  width: fit-content;
  z-index: 2;
  top: 0;
  left: 0;
}

.BlockWrapper {
  align-items: center;
  display: flex;
  padding: 0 4px !important;
}

.ItemButton {
  height: 20px;
  min-width: 30px;
  outline: none;
  padding: 0 4px !important;

  &:hover, &:active {
    background-color: transparent !important;
    svg {
      path {
        transition: all ease 0.25s;
        fill: #00A88E !important;
      }
    }
  }

  &Is {
    &Active {
      svg {
        path {
          fill: #23D1AE !important;
        }
      }
    }
  }
}

.Input {
  background: $kasp_color_gray_10;
  border-radius: 3px;
  border: none;
  color: #1D1D1B;
  font-family: 'KasperskySans-300', sans-serif;
  font-size: 16px;
  height: 32px;
  line-height: 20px;
  margin: 0 4px 0 4px;
  min-width: 310px;
  padding: 0 0 0 4px;

  &::placeholder {
    color: $kasp_color_gray;
  }
}

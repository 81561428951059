@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Pagination {
  display: flex;
  padding-top: 8px;
  padding-bottom: 64px;
  justify-content: center;
  max-width: 960px;
  ul {
    flex-wrap: nowrap;
    li {
      &:first-child {
        margin-right: 22px;
      }
      &:last-child {
        margin-left: 22px;
      }
      a {
        cursor: pointer;
        width: 38px;
        height: 38px;
        padding: 0 6px;
        border-radius: 3px;
        background-color: #F2F2F2;
        color: #444;
        &:hover {
          background: #23D1AE!important;
          color: white;
        }
        &[aria-current="true"] {
          background-color: #00A88E;
          color: white;
        }
        @media only screen and (min-width: $lg) {
          width: 32px;
          height: 32px;
        }
        > span {
          display: none;
        }
      }
      &:first-child {
        svg {
          stroke: white;
          stroke-width: 1px;
          transform: scale(5);
          fill: #ccc;
          &:hover {
            fill: #00A88E;
          }
          @media only screen and (min-width: $lg) {
            transform: scale(3);
          }
        }
        a {
          background: transparent;
          &:hover {
            background: transparent!important;
          }
        }
      }
      &:last-child {
        svg {
          stroke: white;
          stroke-width: 1px;
          transform: scale(5);
          fill: #ccc;
          &:hover {
            fill: #00A88E;
          }
          @media only screen and (min-width: $lg) {
            transform: scale(3);
          }
        }
        a {
          background: transparent;
          &:hover {
            background: transparent!important;
          }
        }
      }
    }
  }
}

@import '~@styles/colors.scss';

.Root {
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  &Selected {
    border: 4px solid $kasp_color_green;
    background: linear-gradient(0deg, rgba(35, 209, 174, 0.24), rgba(35, 209, 174, 0.24)), #EBF5F5;
  }

}

.Link {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  text-decoration: none !important;
  letter-spacing: 0.03em;

  svg {
    width: 11px;
    height: 8px;
    display: inline-block;
    margin: 0 10px 1px;
    transition: all ease 0.25s;
    path {
      transition: all ease 0.25s;
    }
  }

  &:active, &:visited {
    color: #00A88E;

    svg {
      path {
        fill: #00A88E;
      }
    }
  }

  &:hover {
    transition: all ease 0.3s;
    color: #23D1AE !important;

    svg {
      transform: translateX(4px);
      path {
        fill: #23D1AE;
      }
    }
  }
}

.ActionsPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  button {
    background-color: transparent;
    border: transparent !important;
    outline: transparent;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
    height: 17px;
    width: 17px;
    margin: 3px;
  }
}

.ButtonEdit, .ButtonDelete {
  width: 17px !important;
  height: 17px !important;
  padding: 0 !important;
  margin: 0 4px !important;
  svg path {
    transition: all ease 0.25s;
  }
}

.ButtonEdit {
  &:hover, &:active {
    svg path {
      fill: #23D1AE;
    }
  }
}

.ButtonDelete {
  &:hover, &:active {
    svg path {
      fill: #FF3748;
    }
  }
}

.ModalContent {
  padding: 10px 24px 20px 24px !important;
  display: flex;
  flex-direction: column;
}

.ModalInput {
  margin: 0 0 22px 0 !important;
  input {
    min-width: 432px !important;
  }
}

.ModalButton {
 min-width: 208px !important;
}

@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Root {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12;
  padding: 24px;
  @media only screen and (max-width: $md - 1) {
    padding: initial;
  }
  @media only screen and (max-height: $md) and (orientation: landscape) {
    align-items: initial!important;
    padding: initial;
  }
}

.Title {
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px !important;
  line-height: 36px !important;
}

.Close {
  position: absolute !important;
  top: 17px;
  right: 24px;
  width: 18px;
  height: 18px;
  z-index: 100;

  &:before {
    content: '✕';
    color: $kasp_color_gray_20;
    font-size: 25px;
  }
}

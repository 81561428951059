@import '~@styles/breakpoints.scss';

.Root {
  width: 80%;
}

.Content {
  display: flex;
  margin: 0 auto 36px auto !important;

  @media only screen and (min-width: $md) {
  }
  
  @media only screen and (min-width: $lg) {
  }
}

.Button {
  margin: 8px !important;
}

@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Root {
  display: flex;
  flex-direction: column;

  &Is {
    &Failed {
      .Input {
        background: red;
        border-color: red;
        box-shadow: 0 0 0 2px red;
      }
    }
  }
}

.Description {
  color: gray;
}

.Error {
  border-color: $kasp_color_red_error!important;

  &Icon {
    margin: 0 4px 0 0;
  }
}

.Header {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin: 0 0 8px;
}

.Info {
  margin: 0 0 0;
}

.Input {
  display: flex!important;

  &.Error {
    border-color: $kasp_color_red_error!important;

    &Icon {
      margin: 0 4px 0 0;
    }
  }

  label {
    z-index: 2;
    // padding-left: 12px;
    color: #999999!important;
    transition: none!important;
    transform: none!important;
    top: 16px!important;
    font-family: 'KasperskySans-300', sans-serif !important;
    pointer-events: none!important;
    &[data-shrink=true] {
      visibility: hidden!important;
    }
  }
  div {
    display: flex;
    align-items: center;
    margin-top: initial!important;
    width: 100%;
    background: #fff;
    // border: 2px solid #CCCCCC;
    line-height: 20px;
    height: 48px;
    // padding: 0 12px;
    transition: all .2s;
    font-family: 'KasperskySans-300', sans-serif !important;
  }
}

.Label {
  &:hover {
    cursor: pointer;
  }
}

.ErrorMessage {
  font-size: 10px;
  line-height: 12px;
  color: $kasp_color_red_error;
}

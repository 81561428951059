@import '~@styles/colors.scss';

.Root {
  //color: $color-blue-300;
  text-decoration: none;
  &:link {
    color: #00A88E !important;
  }

}

@import '~@styles/breakpoints.scss';
@import '~@styles/colors.scss';

.Root {
  position: relative;
  height: initial;
  //background: #EBF5F5 url("../../assets/login_fon.svg") no-repeat center 0;
  @media only screen and (min-width: $lg) {
    background: #EBF5F5 url("../../assets/login_fon.svg") no-repeat center 0!important;
    height: 100vh!important;
  }
  @media only screen and (min-width: $md) {
    background: #fff;
  }
}

.Content {
  max-width: 536px!important;
  padding: initial!important;
  @media only screen and (max-width: $md) {
    height: initial;
    top: initial;
    bottom: initial;
    padding-left: 16px!important;
    padding-right: 16px!important;
  }
}

.Title {
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 32px;
  letter-spacing: 1px;
}

.ReCaptcha {
  height: 76px;
  background: #FAFAFA;
  border: 1px solid #D6D6D6;
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.101138);
  border-radius: 3px;
  margin-top: 24px;
}

.IsTrouble {
  margin-top: 32px;
  @media only screen and (max-width: $md) {
    margin-bottom: 90px;
  }
  > p {
    font-size: 16px;
    line-height: 20px;
    margin: initial;
    &:nth-child(1) {
      margin-bottom: 8px;
    }
    > a {
      color: #00A88E;
      font-size: 16px;
      line-height: 20px;
      text-decoration: none;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }
}

.FormContainer {
  @media only screen and (orientation: landscape) and (max-height: $md) {
    height: 552px;
  }
}

.Button {
  border-width: 2px!important;
  border-radius: 3px!important;
  height: 48px;
  font-size: 16px!important;
  box-shadow: none!important;
  &:disabled {
    background-color: $kasp_color_btn_disable!important;
    box-shadow: none!important;
  }
  &:hover {
    background: $kasp_color_btn_hover!important;
    box-shadow: none!important;
  }
  &:active {
    background: $kasp_color_btn_active!important;
    box-shadow: none!important;
  }
}

.SecondaryButton {
  border-width: 2px!important;
  border-radius: 3px!important;
  height: 48px;
  font-size: 16px!important;
  box-shadow: none!important;
  border-color: $kasp_color_btn_default!important;
  &:disabled {
    background-color: transparent!important;
    box-shadow: none!important;
    border-color: $kasp_color_btn_disable!important;
    color: #CCCCCC!important;
  }
  &:hover {
    background-color: transparent!important;
    box-shadow: none!important;
    border-color: $kasp_color_btn_hover!important;
    color: $kasp_color_btn_hover!important;
  }
  &:active {
    background-color: transparent!important;
    box-shadow: none!important;
    border-color: $kasp_color_btn_active!important;
    color: $kasp_color_btn_active!important;
  }
}

.Message {
  position: relative;
  font-family: 'KasperskySans-500', sans-serif;
  color: #1D1D1B;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  max-width: 499px;
  margin: 16px auto;
  &:before {
    position: absolute;
    content: '';
    background: url("../../assets/icons/check.svg") no-repeat;
    width: 16px;
    height: 12px;
    top: 4px;
    left: 0;
  }
  @media only screen and (max-width: $md) {
    padding-left: 16px!important;
    padding-right: 16px!important;
  }
}

.Name, .Email, .Code {
  input {
    &::placeholder {
      font-family: 'KasperskySans-300' , sans-serif !important;
      color: #999999;
      opacity: 1;
    }
  }
  border: 2px solid #ccc !important;
}

// Shared
@import '~@styles/colors.scss';
@import '~@styles/breakpoints.scss';

.Root {
  position: absolute;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000 !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(0.25rem);

  @media only screen and (min-width: $md) {
  }
  @media only screen and (min-width: $lg) {
  }
}

.Progress {
  @media only screen and (min-width: $md) {
  }
  @media only screen and (min-width: $lg) {
  }
}
